import React, { useState } from "react"
import axios from "axios"
import qs from "qs"
import * as Yup from "yup"
import { Formik, Form } from "formik"
import intersection from "lodash/intersection"

import Progressbar from "./progressbar/progressbar"
import StepPersonal from "./steps/stepPersonal"
import StepCompany from "./steps/stepCompany"
import StepSend from "./steps/stepSend"
import SignupSuccess from "./signupSuccess"

import Headline from "../../atoms/headline/headline"
import Button from "../../atoms/button/button"

import styles from "./signupForm.module.css"

const SignupForm = () => {
  const [activeStep, setActiveStep] = useState(0)

  const textRequiredValidation = "ist ein Pflichtfeld"
  const textEmailValidation = "ist nicht korrekt"
  const textNumberValidation = "muss eine Zahl sein"
  const googleTagManagerFormId = "contactForm"

  const steps = [
    {
      label: "Zu Ihnen",
      component: <StepPersonal />,
      googleTagManagerEventName: "formSuccessStep1",
      validationSchema: Yup.object({
        firstname: Yup.string().required(textRequiredValidation),
        lastname: Yup.string().required(textRequiredValidation),
        email: Yup.string()
          .email(textEmailValidation)
          .required(textRequiredValidation),
      }),
    },
    {
      label: "Zu Ihrem Unternehmen",
      component: <StepCompany />,
      googleTagManagerEventName: "formSuccessStep2",
      validationSchema: Yup.object().shape(
        {
          companyname: Yup.string().required(textRequiredValidation),
          street: Yup.string().required(textRequiredValidation),
          number: Yup.string().required(textRequiredValidation),
          zip: Yup.number()
            .typeError(textNumberValidation)
            .required(textRequiredValidation),
          city: Yup.string().required(textRequiredValidation),
          phone: Yup.string().required(textRequiredValidation),
          ustid: Yup.string().when("taxnumber", {
            is: taxnumber => !taxnumber || taxnumber.length === 0,
            then: Yup.string().required(
              'oder "Steuernummer" muss ausgefüllt sein.'
            ),
          }),
          taxnumber: Yup.string().when("ustid", {
            is: ustid => !ustid || ustid.length === 0,
            then: Yup.string().required(
              'oder "USt - IdNr." muss ausgefüllt sein.'
            ),
          }),
        },
        [["ustid", "taxnumber"]]
      ),
    },
    {
      label: "Zu uns senden",
      component: <StepSend />,
      googleTagManagerEventName: "formSuccessStep3",
      validationSchema: Yup.object({
        agb: Yup.array().required(),
        privacy: Yup.array().required(),
        message: Yup.string(),
        recaptcha: Yup.string().required(),
      }),
    },
  ]

  const initialValues = {
    firstname: "",
    lastname: "",
    email: "",

    companyname: "",
    street: "",
    number: "",
    zip: "",
    city: "",
    phone: "",
    ustid: "",
    taxnumber: "",

    agb: [],
    privacy: [],
    message: "",
    recaptcha: "",
  }

  const isLastStep = () => {
    return activeStep === steps.length - 1
  }

  const trackSubmitEvent = () => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: steps[activeStep].googleTagManagerEventName,
      formId: googleTagManagerFormId,
    })
  }

  const isStepValid = errors => {
    const intersectFields = intersection(
      steps[activeStep].validationSchema._nodes,
      Object.keys(errors)
    )
    return intersectFields.length === 0 ? true : false
  }

  const handleNextStep = () => {
    setActiveStep(activeStep + 1)
  }

  const handleBackStep = e => {
    e.preventDefault()
    setActiveStep(activeStep - 1)
  }

  const handleSubmit = async (values, form) => {
    trackSubmitEvent()

    if (!isLastStep()) {
      form.setSubmitting(false)
      handleNextStep()
      form.validateForm()
      form.setTouched({})
      return
    }
    //alert(JSON.stringify(values, null, 2));

    const formData = { ...values }
    formData.agb = true
    formData.privacy = true

    await axios
      .post("/api/mail.php", qs.stringify(formData), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then(response => {
        handleNextStep()
      })
      .catch(error => {
        form.setSubmitting(false)
        console.error("Error ========>", error)
      })
  }

  return (
    <>
      {activeStep === steps.length ? (
        <SignupSuccess />
      ) : (
        <>
          <Headline align="center">In wenigen Schritten Kunde werden</Headline>
          <Progressbar steps={steps} activeStep={activeStep} />
          <Formik
            initialValues={initialValues}
            validationSchema={steps[activeStep].validationSchema}
            onSubmit={handleSubmit}
            validateOnMount
          >
            {formik => (
              <Form className={styles.form}>
                <div className={styles.formSteps}>
                  {steps[activeStep].component}
                </div>
                <div className={styles.buttonBar}>
                  {activeStep > 0 ? (
                    <Button
                      tagName="button"
                      htmlType="button"
                      styleType="back"
                      onClick={handleBackStep}
                    >
                      Zurück
                    </Button>
                  ) : (
                    <div></div>
                  )}
                  <Button
                    tagName="button"
                    styleType="default"
                    htmlType="submit"
                    position="right"
                    disabled={
                      !isStepValid(formik.errors) || formik.isSubmitting
                    }
                  >
                    {isLastStep() ? "Absenden" : "Weiter"}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </>
      )}
    </>
  )
}

export default SignupForm
