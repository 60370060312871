import React from "react"

import Headline from "../../atoms/headline/headline"
import Text from "../../atoms/text/text"

import styles from "./signupSuccess.module.css"

const SignupSuccess = () => {
  return (
    <>
      <Headline>Vielen Dank für Ihre Anfrage,</Headline>
      <Text className={styles.text}>
        die wir natürlich umgehend prüfen und entsprechend bearbeiten!
        <br />
        In Kürze erhalten Sie Ihre Zugangsdaten über eine separate Mail. Noch
        etwas Geduld und der Einkauf kann starten!
      </Text>
    </>
  )
}

export default SignupSuccess
