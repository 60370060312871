import React from "react"

import Layout from "../components/layout"
import Seo from "../components/atoms/seo/seo"
import Stage from "../components/molecules/stage/stage"
import SignupForm from "../components/molecules/signupForm/signupForm"

export default () => (
  <>
    <Seo title="Anmeldung" canonical="/signup" />
    <Layout>
      <Stage>
        <SignupForm />
      </Stage>
    </Layout>
  </>
)
